import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { navy, green } from "../shared/colors";
import { small } from "./responsive";

const Container = styled.footer`
  min-height: 150px;
  z-index: 2;
  display: flex;
  background: ${green};

  @media(max-width: ${small}) {
    flex-direction: column;
  }
`;

const SocialLink = styled.a<{ src: string }>`
  background: url(${props => props.src});
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;
  height: 30px;
  width: 30px;  
  background-position-x: center;
  margin: 0 5px;
`;

const Logo = styled(Link)`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    max-width: 200px;
    width: 100%;
    height: auto;
  }
  @media(min-width: ${small}) {
    flex: 0 0 250px;
  }
`;

const Contact = styled.div`
  color: white;
  background: ${navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  @media(min-width: ${small}) {
    flex: 1;
  }
`;

const Socials = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  background: ${green};
  @media(min-width: ${small}) {
    flex: 0 0 250px;
  }
`;

type SocialLink = { imageUrl: string, externalUrl: string };

const getExternalUrl = (name: string): string => {
  switch (name) {
    case 'fb':
      return 'https://www.facebook.com/eDubServices';
    case 'tw':
      return 'https://twitter.com/edubservices';
    case 'inst':
      return 'https://www.instagram.com/edubservices';
    case 'yt':
      return 'https://www.youtube.com/c/eDubServices/featured';
  }
  throw new Error(`Unexpected social name: ${name}`);
}

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        socials: allFile(filter: {absolutePath: {regex: "/(social)/"}}) {
          nodes {
            absolutePath
            name
            publicURL
          }
        }
      }
    `);
  const links = data.socials.nodes.map((d: any) => {
    return {
      imageUrl: d.publicURL,
      externalUrl: getExternalUrl(d.name)
    }
  });
  return (
    <Container>
      <Logo to="/"><img src="/assets/images/large-logo-white.png" /></Logo>
      <Contact>
        <span>Unit 15, Ousegill Business Park, Great Ouseburn, YO26 9AE</span>
        <span><b>Company No</b>.08732379</span>
      </Contact>
      <Socials>

        {links.map((l: SocialLink, index: number) =>
          (<SocialLink key={index} href={l.externalUrl} src={l.imageUrl} target="_blank"></SocialLink>))}
      </Socials>
    </Container>
  )
};

export default Footer;