import * as React from "react"
import PropTypes from "prop-types"
import GlobalStyle from '../globalStyles';
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import { ToastContainer } from 'react-toastify'
import CookieAccept from "./cookieAccept";
import { CookiesProvider } from "react-cookie";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Main = styled.main<{ headerSize: string }>`
  transition: margin-top 0.5s ease-in-out;
  margin-top: ${props => props.headerSize === 'large'
    ? '320px'
    : '60px'};
  &.shrink {
    margin-top: 60px;
  }
`;

const Layout = ({ children, header, title, transparent }: { children: any, header?: string, title?: string, transparent: boolean }) => {

  let headerSize = 'small';
  if (!!header) {
    headerSize = 'large';
  }
  return (<>
    <GlobalStyle />
    <AppContainer>
      <CookiesProvider>
        <Header header={header} title={title} transparent={transparent} />
        <Main id="main" headerSize={headerSize} title={title}>
          <CookieAccept />
          {children}
        </Main>
        <Footer />
      </CookiesProvider>
    </AppContainer>
    <ToastContainer />
  </>)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
