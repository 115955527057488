import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const navPadding = '20px';

const NavLink = styled(Link)`
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1.5px;
  transition: all 0.1s ease-in-out;
  padding: 0 ${navPadding};
  position: relative;

  &:hover, &.active {
    opacity: 1 !important;

    &::before {
      right: ${navPadding};
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    transition: all .3s ease-in-out;
    left: ${navPadding};
    right: calc(100% - ${navPadding});
    top: -5px;
    background: white;
  }
`;

export const getLinks = () => (
  <div>
    <NavLink to="/about" activeClassName="active">
      About
    </NavLink>
    <NavLink to="/testimonials" activeClassName="active">
      Testimonials
    </NavLink>
    <NavLink to="/gallery" activeClassName="active">
      Gallery
    </NavLink>
    <NavLink to="/weddings" activeClassName="active">
      Wedding Hire
    </NavLink>
    <NavLink to="/contact" activeClassName="active">
      Contact
    </NavLink>
    <NavLink to="https://edubconversions.co.uk/edub-trips-booking" activeClassName="active">
      Book
    </NavLink>
    <NavLink to="https://edubconversions.co.uk/" activeClassName="active">
      Conversions
    </NavLink>
  </div>
);