import { createGlobalStyle } from 'styled-components'
import { green, grey, lilac } from './shared/colors';
 
const GlobalStyle = createGlobalStyle`
  html {
    background: ${grey};
    font-family: Raleway;
    body {
      margin: 0;
    }
  }
  main {
    font-family: Raleway;
    font-size: 11pt;
    color: ${lilac};
    flex: 1;

    h1, h2, h3, h4, h5, h6 {
      font-family: Montserrat;
    }

    a {
      color: ${green};
      text-decoration: none;
    }

    input {
      font-family: Raleway;
      border: 1px solid rgba(0,0,0,0.2);
      border-radius: 3px;
      padding: 4px 8px;
    }
  }

  .image-gallery-slide-wrapper .image-gallery-icon:hover {
    color: ${grey};
  }

  .ReactModal__Overlay {
    z-index: 200;
  }
`;
 
export default GlobalStyle;