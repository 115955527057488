import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import Modal from 'react-modal';
import styled from "styled-components";
import { green, grey } from "../shared/colors";

const height = '28px';

const Container = styled.div<{ show: boolean }>`
    z-index: 100;
    position: sticky;
    top: calc(100vh - ${height});
    height: 0;
    ${props => props.show ? '' : 'display: none;'}
    > span {
        background: ${grey};
        padding: 10px;
        border-top-right-radius: 10px;
    }
`;

const DismissButton = styled.div`
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    font-weight: 800;
    opacity: 0.5;
`;

const AcceptButton = styled.button`
    background: ${green};
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    padding: 3px 10px;
    text-transform: uppercase;
    color: white;
    border: 0;
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '300px',
        zIndex: 200
    },
};

const CookieAccept = () => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [hasCookie, setHasCookie] = React.useState(false);
    const [cookies, setCookie] = useCookies(['eDubs-tracking-consent']);

    useEffect(() => {
        setHasCookie(cookies['eDubs-tracking-consent'] != null);
    }, [cookies])

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function accept() {
        setCookie('eDubs-tracking-consent', true);
    }
    Modal.setAppElement('#___gatsby');

    return (
        <Container show={!hasCookie}>
            <span>By using this site, you agree to our use of <a href="javascript:void(0)" onClick={openModal}>cookies.</a> &nbsp; <AcceptButton onClick={accept}>Accept</AcceptButton></span>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
            >
                This website stores data such as cookies to enable site functionality including analytics and personalization.
                <DismissButton onClick={closeModal}>x</DismissButton>
                <br />
                <br />
                <AcceptButton onClick={accept}>Accept</AcceptButton>
            </Modal>
        </Container>
    );
}

export default CookieAccept;