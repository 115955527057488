import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { green, lilac, navy } from "../shared/colors";
import Responsive, { medium } from "./responsive";
import { getLinks } from "./navLinks";

const Nav = styled.header<{ backgroundUrl?: string, transparent: boolean }>`
  padding: 10px 20px;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: start;
  backface-visibility: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: ${props => !!props.backgroundUrl ? '300px' : '60px'};
  background-image: url(${props => props.backgroundUrl});
  ${props => props.transparent ? '' : `background: ${navy};`}
`;

const NavLinksLarge = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 959px) {
    display: none;
  }

  > div:last-child {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 1.45rem 1.0875rem;
    margin: 0 auto;
    flex: 1;
    a {
      opacity: 0.7;
    }
  }

  a {
    color: white;
  }
`;

const NavLinksSmall = styled.div`
  > input {    
    display: block;
    width: 35px;
    height: 32px;
    position: absolute;
    top: 20px;
    left: 15px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    &:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;

      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
      }
    }
    &:checked ~ div {
      transform: none;
    }
  }
  > span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    
    background: white;
    border-radius: 3px;
    
    z-index: 1;
    
    transform-origin: 4px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;

    top: 17px;
    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0 3px;
    }
  }

  @media (min-width: 960px) {
    display: none;
  }

  > a:first-child {
    position: absolute;
    height: 50px;
    top: 13px;
    left: calc(50% - 30px);
  }
`;

const Title = styled.div<{ hasImage: boolean }>`
  color: white;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 5px;
  font-size: 3rem;
  position: absolute;
  top: ${props => props.hasImage ? '50%' : '100%'};
  right: 0;
  left: 0;
  text-align: left;

  > span {
    color: ${green};
  }

  ${Responsive};

  @media(max-width: ${medium}) {
    font-size: 2rem;
  }
`;

const navPadding = '20px';
const NavLink = styled(Link)`
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1.5px;
  transition: all 0.1s ease-in-out;
  padding: 0 ${navPadding};
  position: relative;

  &:hover, &.active {
    opacity: 1 !important;

    &::before {
      right: ${navPadding};
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    transition: all .3s ease-in-out;
    left: ${navPadding};
    right: calc(100% - ${navPadding});
    top: -5px;
    background: white;
  }
`;

const Logo = styled(Link)`
  background: url('./assets/images/logo-white.png');    
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  min-width: 50px;
  z-index: 4;
`;

const MenuSlider = styled.div`
  position: absolute;
  top: -35px;
  left: 0;
  
  background: #ededed;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  width: 100%;

  > div {
    display: flex;
    flex-direction: column;    
    margin: 100px 0 20px 0;
    > a {
      color: ${lilac};
      padding: 10px 35px;
    }
  }
`;

const InitScrollListener = (window: any) => {
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    const navbarElement = document.getElementById("navbar");
    const titleElement = document.getElementById("title");
    if (navbarElement && titleElement) {
      const height = Math.max(300 - currentScrollPos, 50);
      const opacity = (height - 60) / 240;
      navbarElement.style.minHeight = `${height}px`;
      titleElement.style.opacity = `${opacity}`;
    }
  }
}

const Header = ({ header, title, transparent }: { header?: string, title?: string, transparent: boolean }) => {
  if (typeof window !== `undefined`) {
    if (!!header) {
      InitScrollListener(window);
    } else {
      window.onscroll = function () { };
    }
  }

  return (<Nav backgroundUrl={header} id="navbar" transparent={transparent}>
    <Title hasImage={!!header} id="title">{title}{title ? <span>.</span> : <></>}</Title>
    <NavLinksLarge>
      <Logo to="/" />
      {getLinks()}
    </NavLinksLarge>
    <NavLinksSmall>
      <Logo to="/" />
      <input type="checkbox"></input>
      <span></span>
      <span></span>
      <span></span>
      <MenuSlider>
        {getLinks()}
      </MenuSlider>
    </NavLinksSmall>
  </Nav>
  )
};
export default Header;
