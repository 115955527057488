export const large = '920px';
export const medium = '768px';
export const small = '576px';


const Responsive = `
    max-width: ${large};
    margin: auto;
    
    @media(max-width: ${large}) {
        max-width: ${medium};
    }
    
    @media(max-width: ${medium}) {
        max-width: ${small};
    }
    
    @media(max-width: ${small}) {
        padding: 0 10px;
    }
`;

export default Responsive;